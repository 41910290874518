<template>
  <div>
    <div class="overviewTop">
      <div class="tipBox">
        <p>账户余额</p>
        <div class="ye" style="color:#E3405B">{{detail.balance_account?detail.balance_account:0 | unitPrice("￥")}}</div>
      </div>
      <div class="tipBox">
        <p>现金账户余额</p>
        <div
          class="ye" style="color:#5DBE2D"
        >{{detail.lock_account + detail.un_account ?detail.lock_account + detail.un_account :0 | unitPrice("￥")}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import * as API_account from "@/api/account";
import * as API_logistics from "@/api/expressCompany";
import { handleDownload } from "@/utils";
import { Foundation } from "@/../ui-utils";
export default {
  name: "goodsList",
  data() {
    return {
      yjShow: false,
      czShow: false,
      glShow: false,
      txShow: false,
      zzShow: false,
      txxqShow: false,
      /** 列表loading状态 */
      loading: false,
      importLoading: false,
      idsList: [],
      zzInfo: {},
      zzRules: {
        a8a8: [{ required: true, message: "请输入转账金额", trigger: "blur" }]
      },
      txInfo: {},
      txRules: {
        a7a7: [{ required: true, message: "请输入提现金额", trigger: "blur" }],
        b7b7: [
          { required: true, message: "请选择提现方式", trigger: "change" }
        ],
        c7c7: [{ required: true, message: "请选择提现账户", trigger: "change" }]
      },
      czInfo: {},
      czRules: {
        a1a1: [{ required: true, message: "请输入预存金额", trigger: "blur" }]
      },
      yjInfo: {},
      yjRules: {
        a3a3: [{ required: true, message: "请输入预警值", trigger: "blur" }]
      },
      glInfo: {},

      /** 列表参数 */
      params: {},

      glList: [{}],

      list1: [
        { value: 1, label: "选项1" },
        { value: 2, label: "选项2" },
        { value: 3, label: "选项3" },
        { value: 4, label: "选项4" }
      ],
      list2: [
        { value: 1, label: "选项1" },
        { value: 2, label: "选项2" },
        { value: 3, label: "选项3" },
        { value: 4, label: "选项4" }
      ],
      list3: [
        { value: 1, label: "账号1" },
        { value: 2, label: "选项2" },
        { value: 3, label: "选项3" },
        { value: 4, label: "选项4" }
      ],

      daterangeDate: "",

      /** 列表数据 */
      tableData: {},

      /** 列表分页数据 */
      pageData: [],

      /** 快递列表 */
      logiList: [],

      /** 店铺信息 */
      shopInfo: this.$store.getters.shopInfo,
      detail: ""
    };
  },
  activated() {
//     this.GET_AccountList();
//     this.GET_logisticsList();
				getAcountMoney()
  },
  mounted() {
//     this.GET_AccountList();
//     this.GET_logisticsList();
			 this.getAcountMoney()
  },
  methods: {
    getAcountMoney() {
      API_account.getShopBalance().then(response => {
        console.log(response, "response");
        this.detail = response;
      });
    },
    daterangeDateChange(val) {
      this.params.start_time = val ? val[0] / 1000 : "";
      this.params.end_time = val ? val[1] / 1000 : "";
    },
    daterangeDateChange1(val) {
      this.params.start_time1 = val ? val[0] / 1000 : "";
      this.params.end_time1 = val ? val[1] / 1000 : "";
    },

    submitImport() {
      this.importLoading = true;
      API_account.getSettleOrderList({}).then(response => {
        let nameLabel =
          this.shopInfo.shop_type !== 2 ? "供应商名称" : "店铺名称";
        let nameValue =
          this.shopInfo.shop_type !== 2 ? "seller_name" : "shop_name";
        let priceValue =
          this.shopInfo.shop_type === 2 ? "order_price" : "shop_order_price";
        let pointValue =
          this.shopInfo.shop_type === 2 ? "consume_point" : "shop_consume_point";
        response.forEach(item => {
          item.complete_time = Foundation.unixToDate(
            item.complete_time,
            "yyyy-MM-dd hh:mm"
          );
          if (item[pointValue]) {
            item[priceValue] =
              item[priceValue] + " + " + item[pointValue] + "积分";
          }
        });
        let tHeaders = [
          nameLabel,
          "订单号",
          "买家会员号",
          "订单金额",
          "快递单号",
          "快递名称",
          "订单日期"
        ];
        let filterVals = [
          nameValue,
          "sn",
          "member_name",
          priceValue,
          "ship_no",
          "logi_name",
          "complete_time"
        ];
        handleDownload(response, tHeaders, filterVals, "结算订单列表");
        this.importLoading = false;
      });
    },

    /** 获取物流公司信息*/
    GET_logisticsList() {
      API_logistics.getExpressCompanyList({}).then(response => {
        this.logiList = response;
      });
    },

    /** 分页大小发生改变 */
    handlePageSizeChange(size) {
      this.params.page_size = size;
      this.GET_AccountList();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange(page) {
      this.params.page_no = page;
      this.GET_AccountList();
    },

    /** 搜索事件触发 */
    searchEvent(data) {
      this.params = {
        ...this.params,
        keyword: data
      };
      this.GET_AccountList();
    },

    GET_AccountList() {
      this.loading = true;

      if (this.shopInfo.shop_type == 2) {
        this.params.settle_status = 0;
      } else {
        this.params.shop_settle_status = 0;
      }

      API_account.getSettleOrderPage(this.params).then(response => {
        this.loading = false;
        this.pageData = {
          page_no: response.page_no,
          page_size: response.page_size,
          data_total: response.data_total
        };
        this.tableData = response.data;
      });
    },

    handleSelectionChange(val) {
      let ids = [];
      val.forEach(item => {
        ids.push(item.order_id);
      });
      this.idsList = ids;
    },
    settlement() {
      if (this.idsList.length) {
        API_account.checkaccountAdd({
          orderId: this.idsList,
          caDirection: this.params.ca_type
        }).then(res => {
          this.$message.success("发起结算成功");
          this.GET_AccountList();
        });
      } else {
        this.$message.error("请勾选数据");
      }
    },
    handleStockGoods(row) {
      this.$router.push("/order/detail/" + row.sn);
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/ div.toolbar {
  height: 110px;
  padding: 20px 0;
}

/deep/ .el-table {
  width: 100%;
  overflow: auto;
  & td:not(.is-left) {
    text-align: center;
  }
}

.inner-toolbar {
  // display: flex;
  // flex-direction: row;
  // flex-wrap: nowrap;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

/deep/ .pop-sku {
  .toolbar {
    display: none;
  }
  .el-dialog__body {
    .el-table {
      border: 1px solid #e5e5e5;
    }
  }
  .el-table__body-wrapper {
    max-height: 400px;
    overflow-y: scroll;
  }
}

.toolbar-search {
  margin-right: 10px;
  width: 20%;
  text-align: right;
}

div.toolbar-btns {
  // display: flex;
  // flex-direction: row;
  // flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  div {
    span {
      display: inline-block;
      font-size: 14px;
      color: #606266;
    }
  }
  .conditions {
    // display: flex;
    // flex-direction: row;
    // flex-wrap: nowrap;
    // justify-content: flex-start;
    align-items: center;
    min-width: 24.5%;
    .choose-machine {
      width: 60%;
    }
  }
}

.goods-image {
  width: 50px;
  height: 50px;
}

/deep/ div.cell {
  overflow: hidden;

  text-overflow: ellipsis;

  display: -webkit-box;

  -webkit-box-orient: vertical;

  -webkit-line-clamp: 2;
}
/*下架原因*/
.under-reason {
  color: red;
  cursor: pointer;
}
</style>
<style lang="scss" scoped>
.toolbar-btns {
}
.conditions {
  display: inline-block;
}
.hui {
  color: #ccc;
}
.hui span {
  color: #ccc;
}

.toolbar-btns p {
  margin: 0;
  line-height: 30px;
  margin-bottom: 15px;
  font-size: 14px;
}
.overviewTop {
  overflow: hidden;
  margin-bottom: 10px;
}
.tipBox {
  background: #fff;
  padding: 20px;
  display: inline-block;
  width: 49%;
  border-radius: 5px;
}
.tipBox:first-child {
  float: left;
}
.tipBox:last-child {
  float: right;
}
.tipBox p {
  margin: 0;
  line-height: 18px;
  margin-bottom: 15px;
  font-size: 14px;
}

.tipBox .ye {
  color: #333333;
  /* font-size: 18px;
  line-height: 18px;
  margin-bottom: 15px; */
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
  padding: 15px 0px;
}

.tipBox .right {
  float: right;
  font-size: 12px;
  color: #333;
}
.tipBox .right span {
  margin-right: 10px;
}

.overviewCenter {
  background: #fff;
  padding: 20px;
  overflow: hidden;
}
.overviewCenter h4 {
  margin: 0;
  line-height: 36px;
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: normal;
}
.overviewCenter .box {
  display: inline-block;
  width: 49%;
  margin-bottom: 10px;
  font-size: 14px;
}
.overviewCenter .box.first-child {
  float: left;
  border-right: 1px solid #eee;
}
.overviewCenter .box.last-child {
  float: right;
}
.overviewCenter .box > div {
  width: 70%;
  margin: auto;
}
.overviewCenter .box > div span {
  float: right;
}

.overviewBottom {
  overflow: hidden;
  margin-top: 10px;
}
.overviewBottom .box {
  float: left;
  background: #fff;
  padding: 30px 20px;
  margin-right: 2%;
  width: 32%;
  text-align: center;
}
.overviewBottom .box img {
  display: block;
  margin: auto;
  height: 50px;
}
.overviewBottom .box h3 {
  font-size: 16px;
  color: #999;
  margin: 0;
  line-height: 50px;
  font-weight: normal;
}
.linkAll {
  font-size: 14px;
  color: #999;
}
.overviewBottom .box a {
  font-size: 14px;
  color: #1a43a9;
  margin: 0 10px;
}
.overviewBottom .box:last-child {
  margin-right: 0;
}

.addMealForm >>> .el-radio--small.is-bordered {
  height: 42px;
  padding: 0;
}
.addMealForm >>> .el-radio--small.is-bordered .el-radio__input {
  display: none;
}
.addMealForm >>> .el-radio--small.is-bordered .el-radio__label {
  padding-left: 0;
}
.addMealForm >>> .el-radio--small.is-bordered img {
  height: 40px;
}
.formTip {
  line-height: 20px;
  font-size: 12px;
  color: #999;
}
.yjTip {
  width: 400px;
  text-align: center;
  line-height: 50px;
  background: #eee;
  color: #333;
  font-size: 14px;
  margin: auto;
  margin-bottom: 30px;
}

.dialog-footer {
  text-align: right;
}

.conditions {
  display: inline-block;
  margin-right: 20px;
}
.conditions >>> .el-input {
  display: inline-block;
}
.conditions span {
  font-size: 14px;
  color: #606266;
}

.glMoney {
  border: 1px solid #eee;
  padding: 20px;
  margin-bottom: 20px;
  padding-bottom: 10px;
}
.glMoney .top {
  overflow: hidden;
}
.glMoney .top .left {
  float: left;
  width: 200px;
}
.glMoney .top .right {
  float: left;
  border-left: 1px solid #eee;
  padding-left: 70px;
}
.glMoney .top h3 {
  font-size: 16px;
  color: #999;
  font-weight: normal;
  margin: 0;
}
.glMoney .top h4 {
  font-size: 24px;
  color: #cc3300;
  font-weight: normal;
  margin: 0;
  line-height: 70px;
}
.glMoney .top .right h5 {
  font-size: 20px;
  font-weight: normal;
  line-height: 50px;
  color: #cc3300;
  margin: 0;
}
.glMoney .top .right h6 {
  font-size: 20px;
  font-weight: normal;
  line-height: 32px;
  color: #31ca8b;
  margin: 0;
  margin-top: 10px;
}
.glMoney .top .right .btnRight {
  float: right;
  margin-left: 30px;
  margin-top: -2px;
}
.glMoney .tip {
  margin-top: 10px;
}
.glMoney .tip p {
  color: #999;
}
.t1 {
  position: relative;
  top: -1px;
}

.txxqMoney {
  border: 1px solid #eee;
  padding: 20px;
  margin-bottom: 20px;
  overflow: hidden;
}
.txxqMoney .left {
  float: left;
}
.txxqMoney .right {
  float: right;
}
.txxqMoney p {
  margin: 0;
  line-height: 30px;
  height: 30px;
}
</style>















